import "./import/modules";

window.onload = function () {
  // Вьюпорт, ширина и высота
  const [ viewportWidth, viewportHeight ] = getViewport()
  // Контейнер для бургера
  const $toggleNav = document.getElementById("toggleNav");
  // Сам элемент бургера
  const $toggleNav__el = document.getElementById("toggleNav__el");
  // Боковая навигация
  const $asideNav = document.getElementById("aside-nav");
  // Мобильное ли устройство
  const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)

  // Сразу же ставим топ
  $toggleNav.style.top = `${viewportHeight - ($toggleNav.offsetHeight + 16)}px`;

  // Отступ на скролле
  isMobile ? $asideNav.classList.toggle('adaptive__aside-correction_desktop') : ''

  // Клик на бургер
  $toggleNav.addEventListener('click', function (e) {
    e.stopPropagation();
    $toggleNav.classList.toggle('mobile-toggler_active');
    $toggleNav__el.classList.toggle('is-active');
    $asideNav.classList.toggle('adaptive__aside-correction_active');
  })

  window.addEventListener('resize', function (e) {
    $toggleNav.style.top = `${viewportHeight - ($toggleNav.offsetHeight + 16)}px`;
  })
}

function getViewport() {

  let viewPortWidth;
  let viewPortHeight;

  // Дефолт
  if (typeof window.innerWidth != 'undefined') {
    viewPortWidth = window.innerWidth,
        viewPortHeight = window.innerHeight
  }

  // IE6 Стандартный режим
  else if (typeof document.documentElement != 'undefined'
      && typeof document.documentElement.clientWidth !=
      'undefined' && document.documentElement.clientWidth != 0) {
    viewPortWidth = document.documentElement.clientWidth,
        viewPortHeight = document.documentElement.clientHeight
  }

  // Старые версии IE
  else {
    viewPortWidth = document.getElementsByTagName('body')[0].clientWidth,
    viewPortHeight = document.getElementsByTagName('body')[0].clientHeight
  }
  return [viewPortWidth, viewPortHeight];
}
